import { shuffle } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment, PureComponent } from 'react';
import { Image } from 'react-img-webp';

class BrandsSection extends PureComponent {
	static propTypes = {
		brandList: PropTypes.array,
	};

	state = {
		status: 'initial',
	};

	render() {
		const brands = this.props.brandList;
		var name = '1-800-Battery';
		if (this.props.masterProvider && this.props.masterProvider.isActive) {
			name = this.props.masterProvider.name;
		}

		return (
			<div>
				{brands && (
					<div className="container">
						<div className="brands-section">
							<div className={`fader-container ${this.state.status}`}>
								<div className="title">
									<div className="inner">
										<h2 className="title-inner1">{`Featured at ${name}`}</h2>
									</div>
								</div>

								<div className="fader left"></div>
								<div className="fader right"></div>
								<div className="fader bottom"></div>
								{/* <div className="btn-container">
									<button onClick={() => this.setState({ status: 'expanded' })} className="btn">
										<i className="fas fa-angle-down"></i> View More
									</button>
								</div> */}

								<div className="images-container">
									{
										// Render logos partially on the initial load
										shuffle(
											brands.slice(0, this.state.status === 'expanded' ? brands.length - 1 : 22)
										).map((brand, index) => {
											let imgurl = '/assets/images/dummy.png';

											if (brand.imageUrl) {
												const imageName = brand.imageUrl.split('/').pop();
												const ext = imageName.split('.')[1];
												imgurl = `/assets/images/brands/${imageName.split('.')[0]}.${ext}`;
											}

											if (imgurl) {
												return (
													<Image
														key={index}
														alt="brand"
														src={imgurl}
														webP={imgurl}
														layout="intrinsic"
														blur="true"
													/>
												);
											}
										})
									}
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default BrandsSection;
